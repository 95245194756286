@font-face {
  font-family: 'Destiny';
  src: url("./fonts/futur2.ttf") format("truetype");
}

.background {
  background-image: url('./images/2022_Vow_Raid_Raid_Key_Art02.jpg');
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.logo {
  width: 100%;
  margin-bottom: 24px;
}

h1, h2 {
  margin: 0;
  font-family: 'Destiny', 'Helvetica', sans-serif;
  color: white;
}

h1 {
  font-size: 24px;
}

h2 {
  font-size: 20px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  backdrop-filter: blur(6px);
}

.time-until {
  margin-bottom: 12px;
}

@media screen and (min-width: 800px) {
  h1 {
    font-size: 36px;
  }

  h2 {
    font-size: 24px;
  }
}

@media screen and (min-width: 1200px) {
  h1 {
    font-size: 48px;
  }

  h2 {
    font-size: 36px;
  }
}
